import rolodex from 'goodeggs-rolodex';
import {Helmet} from 'react-helmet-async';
import {connect} from 'react-redux';
import React, {Component} from 'react';

import assetPath from 'web/helpers/asset_path';
import MarketLayout from 'web/components/market_layout';

import PrintableGiftCard from '../components/printable_gift_card';

class ThankYouPage extends Component {
  static reducer(state, action) {
    return MarketLayout.reducer(state, action);
  }

  render() {
    return (
      <MarketLayout disableUpcomingOrdersBanner disableBasketDropdown>
        <Helmet>
          <title>Thank You | Good Eggs</title>
        </Helmet>

        {this.props.giftCard.deliveryMethod === 'email' ? (
          <div className="gift-cards-thank-you-page">
            <h1 className="gift-cards-thank-you-page__title">Gift card sent!</h1>
            <img
              className="gift-cards-thank-you-page__image"
              src={`${assetPath('/img/jujube/gift_cards/giftcard-photo.png')}?auto=format`}
              alt="Gift Card"
            />
            <p className="gift-cards-thank-you-page__copy">
              We’ve also sent you an email confirmation of your order.
            </p>
            <a className="button" href="/giftcards">
              Send Another Gift
            </a>
          </div>
        ) : (
          <div className="gift-cards-thank-you-page">
            <h1 className="gift-cards-thank-you-page__title">Your gift card is ready!</h1>
            <p className="gift-cards-thank-you-page__copy">
              Print it now or use the link we emailed you to print it later.
            </p>
            <a
              className="button"
              href={`/giftcards/print?giftCardId=${this.props.giftCard.id}`}
              target="_blank"
              rel="noopener"
            >
              Print Gift Card
            </a>
            <PrintableGiftCard
              giftCard={this.props.giftCard}
              eaterHelp={{
                phone: rolodex.eaterHelpPhoneNumber(),
              }}
            />
            <a className="button" href="/giftcards">
              Send Another Gift
            </a>
          </div>
        )}
      </MarketLayout>
    );
  }
}

ThankYouPage.pageName = 'Gift Card Thank You';

export default connect((state) => state)(ThankYouPage);
